import React from "react";
import { ReactController } from "../react_controller";

import MicrositeEditor from "../../components/MicrositeEditor";

export class MicrositeEditorController extends ReactController {
  connect() {
    this.root.render(<MicrositeEditor {...this.propsValue} />);
  }
}
