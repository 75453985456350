import { Controller } from "@hotwired/stimulus";

export class VisibilityToggleController extends Controller {
  static targets = ["visibilityTrigger", "hide"];

  connect() {
    this.setVisibility();
  }

  setVisibility() {
    if (this.visibilityTriggerTarget.checked) {
      this.hideTargets.forEach((el) => (el.hidden = false));
      this.hideTargets.forEach((el) => (el.style.display = "block"));
    } else {
      this.hideTargets.forEach((el) => (el.hidden = true));
      this.hideTargets.forEach((el) => (el.style.display = "none"));
    }
  }
}