import React, { FC } from "react";
import { GridRow } from "@getregistered/greg-editors";

export interface PrintButtonProps {
  selectedItems?: GridRow[];
  remainingItemsCount: number;
  batchSize: number;
  onClick: () => void;
}

export const PrintButton: FC<PrintButtonProps> = ({
  remainingItemsCount,
  selectedItems,
  batchSize,
  onClick,
}) => {
  if (selectedItems !== undefined && selectedItems.length > 0) {
    return (
      <button onClick={onClick} className="action mls btn btn-large btn-primary">
        Print {selectedItems.length} Selected
      </button>
    );
  }

  if (remainingItemsCount === 0) {
    return (
      <button className="action mls btn btn-large btn-primary" disabled>
        Select Items to Print
      </button>
    );
  }

  return (
    <button onClick={onClick} className="action mls btn btn-large btn-primary">
      Print {remainingItemsCount <= batchSize ? "Remaining" : "Next"}{" "}
      {Math.min(remainingItemsCount, batchSize)}
    </button>
  );
};
