import { Controller } from "@hotwired/stimulus";

export class DirtyFormController extends Controller {
  static defaultConfirmMessage =
    "You have unsaved changes, are you sure you want to leave this page?";
  connect() {
    const message =
      this.element.dataset.confirm || DirtyFormController.defaultConfirmMessage;
    this.setDirtyListener = () => {
      this.dirty = true;
    };
    this.beforeVisitListener = (event) => {
      if (this.dirty) {
        if (!confirm(message)) {
          event.preventDefault();
        }
      }
    };
    // User the native window.onbeforeunload to show a confirmation prompt when the user refreshes
    // the page, closes the tab or otherwise navigates away from the page without involving turbolinks
    // This may look a bit odd but we need to allow for cross browser inconsistency
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    // Note that we can only invoke the browser's native prompts here
    this.beforeUnloadListener = (event) => {
      if (this.dirty) {
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
      delete event["returnValue"];
    };
    this.element.addEventListener("submit", () => (this.dirty = false));
    Array.from(this.element.elements).forEach((el) =>
      el.addEventListener("change", this.setDirtyListener)
    );
    document.addEventListener("turbo:before-visit", this.beforeVisitListener);
    window.addEventListener("beforeunload", this.beforeUnloadListener);
  }

  disconnect() {
    document.removeEventListener(
      "turbo:before-visit",
      this.beforeVisitListener
    );
    window.removeEventListener("beforeunload", this.beforeUnloadListener);
  }
}
