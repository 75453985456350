import { Controller } from "@hotwired/stimulus";

export class RefreshController extends Controller {
  static values = {
    src: String,
  };

  connect() {
    console.log("Connected to reloader...");
    window.reload_interval = setInterval(
      function (obj) {
        console.log("Reloading");
        obj.element.setAttribute("src", obj.srcValue);
      },
      3000,
      this
    );
  }

  disconnect() {
    console.log("Clearing reloader");
    clearInterval(window.reload_interval);
  }
}
