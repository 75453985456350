import { Controller } from "@hotwired/stimulus";

export class MutexCheckboxesController extends Controller {
  static targets = ["checkbox"];

  connect() {
    this.checkboxTargets.forEach((el) => {
      el.addEventListener("change", (event) => {
        if (!event.target.checked) return;

        this.checkboxTargets.forEach((el) => {
          el.checked = false;
        });
        event.target.checked = true;
      });
    });
  }
}
