import React, { FC } from "react";
import { GridRow } from "@getregistered/greg-editors";

export interface ResetButtonProps {
  selectedItems?: GridRow[];
  showResetAll?: boolean;
  onClick: () => void;
}

declare global {
  interface Window {
    swal: (
      args: Record<string, string | boolean>,
      callback: () => void
    ) => void;
  }
}

export const ResetButton: FC<ResetButtonProps> = ({
  selectedItems,
  showResetAll,
  onClick,
}) => {
  const handleResetAllClick = (e) => {
    window.swal(
      {
        title: "Are you sure?",
        text: "This will reset badge print status for the whole event",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        closeOnConfirm: true,
      },
      onClick
    );
  };

  if (selectedItems !== undefined && selectedItems.length > 0) {
    return (
      <button onClick={onClick} className="danger btn btn-large btn-invisible btn-invisible-danger">
        Reset {selectedItems.length} Selected
      </button>
    );
  }
  if (showResetAll) {
    return (
      <button onClick={handleResetAllClick} className="danger btn btn-large btn-invisible btn-invisible-danger">
        Reset All
      </button>
    );
  }
  return null;
};
