import React from "react";
import { ReactController } from "../react_controller";

import RegistrationFormEditor from "../../components/RegistrationFormEditor";

export class FormEditorController extends ReactController {
  connect() {
    this.root.render(<RegistrationFormEditor {...this.propsValue} />);
  }
}
