import { application } from "./application";

import { CheckoutFormController } from "./payment/checkout_form_controller";
import { CurrencyMaskController } from "./form_helpers/currency_mask_controller";
import { DatePickerFormController } from "./form_helpers/date_picker_form_controller";
import { DirtyFormController } from "./form_helpers/dirty_form_controller";
import { FormEditorController } from "./editors/form_editor_controller";
import { GroupedCheckboxController } from "./form_helpers/grouped_checkbox_controller";
import { LeadScanGridController } from "./grids/lead_scan_grid_controller";
import { MicrositeEditorController } from "./editors/microsite_editor_controller";
import { MutexCheckboxesController } from "./form_helpers/mutex_checkboxes_controller";
import { NameBadgeGridController } from "./grids/name_badge_grid_controller";
import { ReadOnlyGridController } from "./grids/read_only_grid_controller";
import { RefreshController } from "./refresh_controller";
import { SimpleGridController } from "@/controllers/grids/simple_grid_controller";

import { RegFormController } from "./form_helpers/reg_form_controller";
import { ReportGridController } from "./grids/report_grid_controller";
import { RoleToggleController } from "./form_helpers/role_toggle_controller";
import { VisibilityToggleController } from "./form_helpers/visibility_toggle_controller";
import { RoomingFieldController } from "./rooming/rooming_field_controller";
import { TipTapTextAreaController } from "./tip_tap_textarea_controller";
import { TranslationFormController } from "./greg_translate/translation-form-controller";

application.register("checkout-form", CheckoutFormController);
application.register("currency-mask", CurrencyMaskController);
application.register("date-picker-form", DatePickerFormController);
application.register("dirty-form", DirtyFormController);
application.register("form-editor", FormEditorController);
application.register("grouped-checkbox", GroupedCheckboxController);
application.register("lead-scan-grid", LeadScanGridController);
application.register("mutex-checkboxes", MutexCheckboxesController);
application.register("microsite-editor", MicrositeEditorController);
application.register("name-badge-grid", NameBadgeGridController);
application.register("read-only-grid", ReadOnlyGridController);
application.register("refresh", RefreshController);
application.register("reg-form", RegFormController);
application.register("report-grid", ReportGridController);
application.register("role-toggle", RoleToggleController);
application.register("visibility-toggle", VisibilityToggleController);
application.register("rooming-field", RoomingFieldController);
application.register("simple-grid", SimpleGridController);
application.register("tip-tap-text", TipTapTextAreaController);
application.register("translation-form", TranslationFormController);
