import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { Controller } from "@hotwired/stimulus";
import {
  gregAdminTheme,
  TipTapContentEditor,
} from "@getregistered/greg-editors";

export class TipTapTextAreaController extends Controller {
  connect() {
    this.textArea.style.display = "none";
    this.content = this.textArea.value;
    this.textArea.addEventListener("change", (e) => {
      const newContent = e.target.value;
      if (newContent !== this.content) {
        this.setContent(newContent);
      }
    });
    this.render();
  }

  setContent(content) {
    this.content = content;
    this.textArea.value = content;
    this.render();
  }

  disconnect() {
    this.reactRoot.unmount();
  }

  render() {
    this.reactRoot.render(
      <ChakraProvider theme={gregAdminTheme}>
        <TipTapContentEditor
          content={this.content}
          onChange={this.setContent.bind(this)}
        />
      </ChakraProvider>
    );
  }

  get textArea() {
    if (this.element.nodeName === "TEXTAREA") {
      return this.element;
    } else {
      this._textArea = this.element.querySelector("textarea");
      return this._textArea;
    }
  }

  get rootEl() {
    if (this._rootEl === undefined) {
      if (this.element.nodeName === "TEXTAREA") {
        this._rootEl = this.element.parentElement;
      } else {
        this._rootEl = this.element;
      }
    }
    return this._rootEl;
  }

  get reactRoot() {
    if (this._reactRoot === undefined) {
      const reactRootEl = document.createElement("div");
      this.rootEl.after(reactRootEl);
      this._reactRoot = ReactDOMClient.createRoot(reactRootEl);
    }
    return this._reactRoot;
  }
}
