import { Grid, GridSaveResult } from "@getregistered/greg-editors";
import { fetchJson } from "../../railsFetch";

export interface Report extends Omit<Grid, "columns"> {
  fields: string[];
  labels: Record<string, string>;
  sort_column?: string;
  sort_order?: string;
  totals_columns?: string[];
}

const mapGridUpdateToReportUpdate = (
  gridUpdate: Partial<Grid>
): Partial<Report> => ({
  filters: gridUpdate.filters,
  sort_column: gridUpdate.sort?.column,
  sort_order: gridUpdate.sort?.order,
  labels: gridUpdate.labels,
  fields: gridUpdate.columns?.map((c) => c.key),
  totals_columns: gridUpdate?.totalsColumns,
});

export const saveReport = async (
  reportUrl: string,
  update: Partial<Grid>
): Promise<GridSaveResult> => {
  const response = await fetchJson(reportUrl, {
    method: "PUT",
    body: JSON.stringify({ report: mapGridUpdateToReportUpdate(update) }),
  });

  const result = (await response.json()) as GridSaveResult;
  return result;
};
