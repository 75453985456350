import React, { FC } from "react";

export interface TotalPrintedProps {
  totalPrinted: number;
  totalItems: number;
}

export const TotalPrinted: FC<TotalPrintedProps> = ({
  totalPrinted,
  totalItems,
}) => (
  <span className="f6">
    Printed <b>{totalPrinted}</b>
    {" / "}
    <b>{totalItems}</b> Badges
  </span>
);
