import { Controller } from "@hotwired/stimulus";

export class GroupedCheckboxController extends Controller {
  static targets = ["toggle"];

  connect() {
    this.toggleTargets.forEach((tog) =>
      tog.addEventListener("change", (event) => {
        this.targetCheckboxes().forEach(
          (element) => (element.checked = event.target.checked)
        );
      })
    );
  }

  targetCheckboxes() {
    return this.element.querySelectorAll("input[type=checkbox]");
  }
}
