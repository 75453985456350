import { Controller } from "@hotwired/stimulus";

export class CurrencyMaskController extends Controller {
  static targets = ["input"];
  connect() {
    this.cleave = new Cleave(this.inputTarget, {
      prefix: this.element.dataset.currencyPrefix + " ",
      numeral: true,
      numeralThousandsGroupStyle: "thousand",
      rawValueTrimPrefix: true,
      swapHiddenInput: true,
    });
  }

  disconnect() {
    this.cleave.destroy();
  }
}
