import React, { FC } from "react";

export interface TemplateSelectProps {
  templates: string[];
  selectedTemplate: string;
  onChange: (template: string) => void;
}

export const TemplateSelect: FC<TemplateSelectProps> = ({
  templates,
  selectedTemplate,
  onChange,
}) => (
  <label style={{ display: "inline-block", paddingTop: "0.125em" }}>
    Template:{" "}
    <select
      style={{ display: "inline" }}
      value={selectedTemplate}
      onChange={(e) => onChange(e.target.value)}
    >
      {templates.map((t) => (
        <option key={t} value={t}>
          {t}
        </option>
      ))}
    </select>
  </label>
);
