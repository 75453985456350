import React, { FC } from "react";
import { getResultColumns, Grid } from "@getregistered/greg-editors";
import { gridLoader } from "../../../domain/grid/resultLoader";
import { PaginatedApplicationGrid } from "../PaginatedApplicationGrid";
import { saveReport } from "./saveReport";
import { renderCheckinCell } from "../CheckinCell/renderCheckinCell";
import { DEFAULT_PER_PAGE, DEFAULT_PER_PAGE_STORAGE_KEY } from "../constants";

export interface PaginatedReportGridProps {
  grid: Grid;
  editable: boolean;
  reportUrl: string;
  totalsUrl: string;
  csvUrl?: string;
  xlsxUrl?: string;
}

export const PaginatedReportGrid: FC<PaginatedReportGridProps> = ({
  grid,
  editable,
  reportUrl,
  totalsUrl,
  csvUrl,
  xlsxUrl,
}) => {
  const loader = gridLoader(
    reportUrl,
    totalsUrl,
    getResultColumns(grid.columns)
  );
  const saveGrid = (update: Partial<Grid>) => saveReport(reportUrl, update);

  const processedGrid = editable
    ? grid
    : {
        ...grid,
        filters: undefined,
        fixedFilters: grid.filters,
      };

  return (
    <PaginatedApplicationGrid
      grid={processedGrid}
      defaultPerPage={DEFAULT_PER_PAGE}
      perPageStorageKey={DEFAULT_PER_PAGE_STORAGE_KEY}
      page={1}
      loader={loader}
      onSave={editable ? saveGrid : undefined}
      renderCell={renderCheckinCell}
      downloadLinks={[
        {
          title: "Download CSV",
          baseUrl: csvUrl,
        },
        {
          title: "Download XLSX",
          baseUrl: xlsxUrl,
        },
      ]}
    />
  );
};
