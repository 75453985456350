import { Controller } from "@hotwired/stimulus";

export class RoleToggleController extends Controller {
  static targets = ["roleSelect", "adminOnly", "regularOnly"];

  connect() {
    this.setVisibility();
  }

  setVisibility() {
    if (this.roleSelectTarget.value === "regular") {
      this.adminOnlyTargets.forEach((el) => (el.hidden = true));
      this.regularOnlyTargets.forEach((el) => (el.hidden = false));
    } else {
      this.adminOnlyTargets.forEach((el) => (el.hidden = false));
      this.regularOnlyTargets.forEach((el) => (el.hidden = true));
    }
  }
}
