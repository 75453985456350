import React from "react";
import { ReactController } from "../react_controller";

import { ReportGrid } from "../../components/grids";

export class ReportGridController extends ReactController {
  connect() {
    this.root.render(<ReportGrid {...this.propsValue} />);
  }
}
