// Map form fields between what the editor expects and what rails expects.

export function fieldsToRails(fields) {
  return fields.map(f => {
    if(f.fields && f.fields.map) {
      f = {...f, fields: fieldsToRails(f.fields)};
    }
    if(f.helpText) {
      f = {...f, help_text: f.helpText}
      delete f.helpText;
    } else {
      delete f.help_text;
    }

    if(f.editLocked) {
      f = {...f, edit_locked: f.editLocked}
    } else {
      delete f.edit_locked;
    }
   
    if(f.officeOnly) {
      f = {...f, office_only: f.officeOnly}
    } else {
      delete f.office_only;
    }
    if(f.defaultValue) {
      f = {...f, default: f.defaultValue}
      delete f.defaultValue;
    } else {
      delete f.default_value;
    }
    
    if(f.dependsOn) {
      f = {...f, depends_on: f.dependsOn}
      delete f.dependsOn;
    } else {
      delete f.depends_on;
    }
    if(f.includeBlank) {
      f = {...f, include_blank: f.includeBlank}
      delete f.includeBlank;
    } else {
      delete f.include_blank;
    }
    return f
  })
}

export function fieldsFromRails(fields) {
  return fields.map(f => {
    if(f.help_text) {
      f = {...f, helpText: f.help_text}
    }
    delete f.help_text;
    if(f.default) {
      f = {...f, defaultValue: f.default}
    }
    delete f.default;
    if(f.office_only) {
      f = {...f, officeOnly: f.office_only}
    }
    delete f.office_only;
    if(f.depends_on) {
      f = {...f, dependsOn: f.depends_on}
    }
    delete f.edit_locked;
    if(f.edit_locked) {
      f = {...f, editLocked: f.edit_locked}
    }
    delete f.depends_on;
    if(f.include_blank) {
      f = {...f, includeBlank: f.include_blank}
    }
    delete f.include_blank;
    if(f.fields && f.fields.map) {
      f = {...f, fields: fieldsFromRails(f.fields)};
    }
    return f;
  })
}
