import React from "react";
import { ReactController } from "../react_controller";

import { NameBadgeGrid } from "../../components/grids";

export class NameBadgeGridController extends ReactController {
  connect() {
    this.root.render(<NameBadgeGrid {...this.propsValue} />);
  }
}
