import { Controller } from "@hotwired/stimulus";

export class TranslationFormController extends Controller {
  static targets = ["translation"];

  populateTranslation(e) {
    this.translationTarget.value = e.target.innerText;
    this.translationTarget.dispatchEvent(
      new Event("change", { bubbles: true, cancelable: true })
    );
  }
}
